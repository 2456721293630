<template>
  <div>
    <v-skeleton-loader class="mx-auto ml-2 mr-2" type="card" :loading="loading">
      <!-- <v-container fluid class="mb-4"> -->
      <CommunityInfo
        :communityInfo="communityInfo"
        @onRelatedCommunityChanged="showRelatedCommunity"
      />
      <InternalBreadcrumbs :breadcrumbs="breadcrumbs" />
      <v-card elevation="0" outlined color="#f1f1f1">
        <v-card-title>New Document</v-card-title>
        <v-card-text>
          <!-- Start Upload document section -->
          <v-form>
            <v-container>
              <v-row>
                <v-col cols="12" md="6">
                  <!-- <v-select
                    v-model="newDocument.documentType"
                    :items="documentTypesItems"
                    item-text="name"
                    item-value="value"
                    label="Choose document type..."
                    required
                  >
                  </v-select> -->
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="4">
                  <MediaUploader
                    class="pb-5"
                    uploadType="file"
                    :key="attachment.id"
                    :id="`multiFilePost_${attachment.id}`"
                    :index="attachment.id"
                    :accept="accept"
                    :multiple="false"
                    :extensions="extensions"
                    :sizeImage="10485760"
                    :sizeVideo="104857600"
                    :fileId.sync="attachment.fileId"
                    :file-url.sync="attachment.url"
                    :isUploading.sync="isFileUploading"
                    @onMultipleUploaded="onMultipleAddFile"
                    @onChange="onChange"
                    @onDeleteFile="onDeleteFile"
                  />
                </v-col>
              </v-row>
              <div class="error--text" v-if="!$v.attachment.url.required">
                Required.
              </div>
              <div class="mt-2">
                <v-btn class="orange-bg white--text" @click="saveDocument">
                  Save Document
                </v-btn>
              </div>
            </v-container>
          </v-form>
          <!-- End Upload document section -->
        </v-card-text>
      </v-card>

      <!-- </v-container> -->
    </v-skeleton-loader>
  </div>
</template>

<script>
import CommunityInfo from "@/view/components/CommunityInfo.vue";
import InternalBreadcrumbs from "@/view/components/DashboardInternalBreadcrumbs.vue";
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
import { GET_QUERY, POST_DATA, API_CUSTOMERS } from "@/core/store/crud.module";
import crud from "@/core/helpers/crud";
import dateFormatter from "@/core/helpers/dateFormatter";
import stringFormatter from "@/core/helpers/stringFormatter";
import { validationMixin } from "vuelidate";
// import dataModels from "@/core/helpers/datamodels.js";
import { required } from "vuelidate/lib/validators";
// import moment from "moment";
import MediaUploader from "@/view/components/imageUploader/mediaUploader.vue";

export default {
  mixins: [validationMixin],
  components: {
    CommunityInfo,
    InternalBreadcrumbs,
    MediaUploader
  },
  data: () => ({
    communityInfo: {},
    newDocument: {
      attachment: {},
      documentType: "Document"
    },

    // documentTypesItems: [
    //   { name: "Document", value: "Document" },
    //   { name: "Contract", value: "Contract" }
    // ],

    isLoading: false,
    loading: false,
    snackbarContent: "",
    snackbarColor: "#F06835",
    breadcrumbs: [],
    attachmentsObj: [{ id: 1, fileId: null, url: null }],
    attachment: { id: 1, fileId: null, url: null },
    isFileUploading: false,
    maxFilesCount: 1
  }),
  props: {
    customerId: {
      type: [Number, String],
      default: 0
    }
  },
  validations() {
    // return true;
    return {
      attachment: {
        url: {
          required
        }
      },
      newDocument: {
        documentType: {
          required
        }
      }
    };
  },
  watch: {},
  async mounted() {},
  async created() {
    let self = this;
    self.loading = true;
    Promise.all([
      await this.getComunityInfo()
      // await this.getRecipients()
    ]).then(function() {
      self.loading = false;
    });
  },
  methods: {
    showRelatedCommunity(communityId) {
      this.$router.push({
        name: "CustomerDetails",
        params: { customerId: communityId }
      });
    },
    async getComunityInfo() {
      this.loading = true;

      let self = this;

      await this.$store
        .dispatch(GET_QUERY, {
          listName: API_CUSTOMERS,
          id: this.customerId
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            self.communityInfo = crud.getPopulatedComunityInfo(response.data);
            self.createBreadcrumb();
            self.setInternalBreadcrumbs();
          } else {
            self.$snackbar.showMessage({
              content: response.data || "Something went wrong!",
              color: "red"
            });
          }

          self.loading = false;
        });
    },
    saveDocument() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.newDocument.id = this.attachment.fileId;
      this.newDocument.propertyId = this.customerId;

      let self = this;
      this.$store
        .dispatch(POST_DATA, {
          listName: `${API_CUSTOMERS}/${this.customerId}/documents`,
          data: this.newDocument
        })
        .then(response => {
          if (response.status >= 200 && response.status <= 204) {
            self.snackbarContent = "Document has been created";

            self.$router.push({
              name: "Documents",
              params: { customerId: this.customerId }
            });
          } else {
            self.snackbarContent = response.data;
            self.snackbarColor = "red";
          }
          self.$snackbar.showMessage({
            content: self.snackbarContent,
            color: self.snackbarColor
          });
        });

      // this.saveCustomer();
    },
    onMultipleAddFile(files) {
      for (var i = 1; i < files.length; i++) {
        let max = Math.max(...this.attachmentsObj.map(i => i.id));
        this.attachmentsObj.push({
          id: (max += 1),
          url: files[i].fileUrl,
          fileId: files[i].id
        });
      }
      let max = Math.max(...this.attachmentsObj.map(i => i.id));
      this.attachmentsObj.push({ id: (max += 1), url: null });
      this.$nextTick(() => files.forEach(f => this.onChange(f)));
    },
    onChange(file) {
      if (file.fileUrl) {
        var found = this.attachmentsObj.find(f => f.url == file.fileUrl);
        found.fileId = file.id;
      }
    },
    onDeleteFile(fileData) {
      let index = this.attachmentsObj.findIndex(f => f.id === fileData.index);
      this.attachmentsObj.splice(index, 1);
    },
    setInternalBreadcrumbs() {
      this.breadcrumbs = [
        {
          title: this.communityInfo.name,
          route: "/customers/" + this.customerId
        },
        {
          title: "Documents",
          route: "/customers/" + this.customerId + "/documents"
        },
        { title: "New Document" }
      ];
    },
    createBreadcrumb() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Customers", route: "/customers" },
        {
          title: this.communityInfo.name,
          route: "/customers/" + this.customerId
        },
        {
          title: "Documents",
          route: "/customers/" + this.customerId + "/documents"
        },
        { title: "New Document" }
      ]);
    },
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
    },
    formatDate(date) {
      return dateFormatter.formatEditDate(date);
    },
    formatDateUs(date) {
      return dateFormatter.formatDateUs(date);
      // return moment.utc(date).format("MM/DD/YYYY");
    },
    capitalizeFirstLetter(string) {
      return stringFormatter.capitalizeFirstLetter(string);
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    }
  },
  computed: {
    accept() {
      return "*/*";
    },
    extensions() {
      return "gif,jpg,jpeg,png,webp,mp4,mov,avi,mkv,mpg,vob,wmv,m4v,asf,srt,pdf,doc,docx,xls,xlsx";
    }
  }
};
</script>
